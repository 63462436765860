export const iconMenu = [
  {
    path: '/painel',
    title: 'Painel',
    icon: '/assets/icons/casa-2.svg',
    activeIcon: '/assets/icons/casa-2-white.svg',
    activePath: false,
    isLine: false,
    isTitle: false,
  },
  {
    path: '/ordem-de-servico',
    title: 'Ordem de Serviço',
    icon: '/assets/icons/icon-lista.svg',
    activeIcon: '/assets/icons/icon-lista-white.svg',
    activePath: false,
    isLine: false,
    isTitle: false,
  },
  // {
  //   path: '/financeiro',
  //   title: 'Financeiro',
  //   icon: '/assets/icons/financeiro.svg',
  //   activeIcon: '/assets/icons/financeiro-white.svg',
  //   activePath: false,
  //   isLine: false,
  //   isTitle: false,
  // },
  {
    path: '/agenda',
    title: 'Agenda',
    icon: '/assets/icons/agenda.svg',
    activeIcon: '/assets/icons/agenda-white.svg',
    activePath: false,
    isLine: false,
    isTitle: false,
  },
  // {
  //   path: '/geolocalizacao',
  //   title: 'Geolocalização',
  //   icon: '/assets/icons/geolocalizacao.svg',
  //   activeIcon: '/assets/icons/geolocalizacao-white.svg',
  //   activePath: false,
  //   isLine: false,
  //   isTitle: false,
  // },
  {
    path: '',
    title: '',
    icon: '',
    activeIcon: '',
    activePath: false,
    isLine: true,
    isTitle: false,
  },
  // {
  //   path: '',
  //   title: 'Informações',
  //   icon: '',
  //   activeIcon: '',
  //   activePath: false,
  //   isLine: false,
  //   isTitle: true,
  // },
  // {
  //   path: '/termos-de-uso',
  //   title: 'Termos de uso',
  //   icon: '/assets/icons/configuracoes.svg',
  //   activeIcon: '/assets/icons/configuracoes-white.svg',
  //   activePath: false,
  //   isLine: false,
  //   isTitle: false,
  // },
  // {
  //   path: '/configuracoes',
  //   title: 'Configurações',
  //   icon: '/assets/icons/configuracoes.svg',
  //   activeIcon: '/assets/icons/help-white.svg',
  //   activePath: false,
  //   isLine: false,
  //   isTitle: false,
  // },
];
