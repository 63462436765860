import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      const tokenStr = sessionStorage.getItem('token');
      if (!tokenStr) {
        return next.handle(req);
      }

      const token = JSON.parse(atob(tokenStr));

      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          Application: `PortalCliente`,
        }
      });

      return next.handle(authReq);
    } catch (error) {
      console.error('Erro ao processar o token:', error);
      return next.handle(req);
    }
  }
}
