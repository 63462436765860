<div class="header">
    <div class="">
        <div class="row align-items-center justify-content-between">
            <div class="col dropdown-notification">
            </div>
            <div class="col-md-7 text-center">
                <h1 class="f-18px">{{ title }}</h1>
            </div>
            <div
                #dropdownNotification
                class="col dropdown-notification position-relative"
                [ngClass]="{ 'open': !!menuOpen }"
                (click)="toggleNotification()"
            >
                <!-- NOTIFICAÇÕES -->
                <div class="dropdown">
                    <button
                        class="btn dropdown-toggle position-relative"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i class="bi bi-bell"></i>
                        <span *ngIf="hasUnreadNotifications()" class="notification-badge"></span>
                    </button>
                    <div class="dropdown-menu">
                        <ul aria-labelledby="dropdownMenuButton" *ngIf="!!drop.notification?.length">
                            <ng-template ngFor let-notification let-i="index" [ngForOf]="drop.notification">
                                <li>
                                    <div
                                      class="dropdown-item"
                                      [class.unread]="!notification.lido"
                                      (click)="setAsRead(notification.id, i); goToNotification(notification)"
                                    >
                                      <strong> {{ notification.descricao }} </strong>
                                      <p> {{ notification.dataCriacao | date: 'dd/MM/yy HH:mm' }} </p>
                                    </div>
                                </li>
                            </ng-template>
                        </ul>
                        <button class="all-read" (click)="seeAll()">
                            Marcar todos como lido
                        </button>
                    </div>
                </div>
            </div>
            <!-- USER -->
            <div class="col-auto pl-2 d-flex align-items-center box-user" (click)="toggleUserMenu()">
              <div class="row align-items-center">
                  <div class="col p-0 text-end">
                      <h5 class="mb-0 text-end">{{user.nome}}</h5>
                      <small class="text-muted pt-0 -mt-2 align-end">{{user.nomeEmpresa}}</small>
                  </div>
                  <div class="col-auto">
                      <img src="/assets/images/user.png" width="45" class="rounded-circle" alt={{user.nome}}>
                  </div>
              </div>
              <div *ngIf="userMenuOpen" class="user-dropdown-menu">
                  <div class="row align-items-center pb-3 border-bottom mb-3">
                      <div class="col-auto">
                          <img src="/assets/images/user.png" width="45" class="rounded-circle" alt={{user.nome}}>
                      </div>
                      <div class="col p-0 text-start">
                          <h5 class="mb-0 text-start">{{user.nome}}</h5>
                          <small class="text-muted pt-0 -mt-2 align-start">{{user.nomeEmpresa}}</small>
                          <small class="text-muted pt-0 -mt-2 align-end">{{user.email}}</small>
                      </div>
                  </div>
                  <ul class="pl-0 pr-0">
                      <li (click)="logout()"> <i class="bi bi-box-arrow-right"></i> Sair</li>
                  </ul>
              </div>
            </div>
        </div>
    </div>
  </div>
