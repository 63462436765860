import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { iconMenu } from 'src/app/shared/utils/icon-menu';
import { NotificacoesService } from 'src/app/services/notificacoes/notificacoes.service';
import { WhiteLabelService } from 'src/app/services/whitelabel/whitelabel.service'; // Certifique-se de importar seu serviço

interface Notification {
  id: number;
  descricao: string;
  dataCriacao?: Date;
  tipo: number;
  ordemServico: string;
  lido: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {

  @Input() currentRout = '';
  title = '';
  hoveredBar = false;
  menuList = iconMenu;
  menuOpen: boolean = false;
  userMenuOpen: boolean = false;
  isLoadingUpload: boolean = false

  drop = {
    notification: [] as Notification[],
    open: false
  };

  user = JSON.parse(atob(sessionStorage['user']));
  userCustom = JSON.parse(atob(sessionStorage['user-custom']));

  @ViewChild('dropdownNotification') dropdownNotification!: ElementRef;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private notificacoesService: NotificacoesService,
    private whiteLabelService: WhiteLabelService,  // Adiciona o serviço
    private router: Router,
    private renderer: Renderer2
  ) {
    this.drop = {
      notification: [],
      open: false
    };

    this.renderer.listen('window', 'click', (e: Event) => {
      const target = e.target as HTMLElement;
      if (this.menuOpen && this.dropdownNotification && !this.dropdownNotification.nativeElement.contains(target)) {
        this.menuOpen = false;
        this.changeDetector.detectChanges();
      }
      if (this.userMenuOpen && target && !target.closest('.box-user')) {
        this.userMenuOpen = false;
        this.changeDetector.detectChanges();
      }
    });
  }

  ngOnInit() {
    this.getNotificationUsers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.title = this.menuList.find((item: any) => item.path == this.currentRout)?.title || '';
    }
  }

  toggleNotification() {
    this.menuOpen = !this.menuOpen;
  }

  toggleUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }

  async getNotificationUsers() {
    try {
      this.notificacoesService.post(`/GetNewNotificacoes`, { MatrizEmail: this.user.email, Pessoa: [0], Limit: 10 }).subscribe(
        res => {
          this.drop = {
            notification: res,
            open: false,
          };
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  goToNotification(notification: Notification) {
    if (notification.tipo == 1) {
      this.router.navigate(['/ordem-de-servico/'], { queryParams: { os: notification.ordemServico } });
    } else {
      this.router.navigate(['/ordem-de-servico/'], { queryParams: { os: notification.ordemServico, chat: true } });
    }
  }

  setAsRead(id: number, i: any) {
    this.drop.notification[i].lido = true;
    this.changeDetector.detectChanges();
    this.notificacoesService
      .put(`/UpdateStatusLido/${id}`, { id: id })
      .subscribe((res: any) => {
        this.changeDetector.detectChanges();
      });
  }

  seeAll() {
    for (let notificacao of this.drop.notification) {
      notificacao.lido = true;
      this.notificacoesService
        .put(`/UpdateStatusLido/${notificacao.id}`, { id: notificacao.id })
        .subscribe((res: any) => {
          this.changeDetector.detectChanges();
        });
    }
    this.changeDetector.detectChanges();
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('login')
  }

  hasUnreadNotifications(): boolean {
    return this.drop.notification?.some(notification => !notification.lido);
  }

  // Função para lidar com o upload do arquivo
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.isLoadingUpload = true;
    if (file) {
      // Verifica o tipo do arquivo
      if (!file.type.startsWith('image/')) {
        alert('Por favor, selecione uma imagem válida.');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      this.whiteLabelService.post('/files-images', formData).subscribe({
        next: (res) => {
          this.changeDetector.detectChanges();
          this.userMenuOpen = false;
          this.whiteLabelService.patch(`/user/${this.userCustom.id}`, {
            thumb: res.id
          }).subscribe(item => {
            this.userCustom = item;
            this.isLoadingUpload = false;
          });
        },
        error: (err) => {
          console.error('Erro ao fazer upload da imagem:', err);
        }
      });
    }
  }
}
